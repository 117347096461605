import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function CellphoneIcon() {
  return ___EmotionJSX("svg", {
    width: "62",
    height: "98",
    viewBox: "0 0 62 98",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-940 -720)",
    fill: "#6B34FB"
  }, ___EmotionJSX("g", {
    transform: "translate(286 256)"
  }, ___EmotionJSX("g", {
    transform: "translate(525 464)"
  }, ___EmotionJSX("g", {
    transform: "translate(129)"
  }, ___EmotionJSX("path", {
    d: "M62,15.5909091 C62,6.98028776 54.5265993,0 45.3076923,0 L16.6923077,0 C7.47340071,0 0,6.98028776 0,15.5909091 L0,82.4090909 C0,91.0197122 7.47340071,98 16.6923077,98 L45.3076923,98 C54.5265993,98 62,91.0197122 62,82.4090909 L62,15.5909091 Z M7.15384615,20.0454545 L54.8461538,20.0454545 L54.8461538,73.5 L7.15384615,73.5 L7.15384615,20.0454545 Z M16.6923077,6.68181818 L45.3076923,6.68181818 C49.645682,6.69497511 53.4278274,9.44051164 54.5123077,13.3636364 L7.48769231,13.3636364 C8.57217259,9.44051164 12.354318,6.69497511 16.6923077,6.68181818 Z M45.3076923,91.3181818 L16.6923077,91.3181818 C11.4243608,91.3181818 7.15384615,87.329446 7.15384615,82.4090909 L7.15384615,80.1818182 L54.8461538,80.1818182 L54.8461538,82.4090909 C54.8461538,87.329446 50.5756392,91.3181818 45.3076923,91.3181818 Z"
  }), ___EmotionJSX("circle", {
    id: "Oval",
    cx: "29.5",
    cy: "85.5",
    r: "3.5"
  })))))));
}