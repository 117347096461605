import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { Section, SectionTitle, Parragraph, Important, AboutMe } from '../../@components/@atoms';
export function AboutMeSection(_ref) {
  var appBarVariant = _ref.appBarVariant;
  return ___EmotionJSX(Section, {
    id: "about-me",
    appBarVariant: appBarVariant
  }, ___EmotionJSX(SectionTitle, null, "Who am I?"), ___EmotionJSX("article", null, ___EmotionJSX(Parragraph, null, "I have an undergraduate degree in Computer Science from Universidad del Norte but I consider myself self-taught person."), ___EmotionJSX(Parragraph, null, "My journey in the software development industry started in 2016. I specialize on front-end development, but I know my way in back-end aswell. Most of my work is built with React."), ___EmotionJSX(Parragraph, {
    marginBottom: 0
  }, "I strive to build products with good UX, I believe that a mindset of \u201C", ___EmotionJSX(Important, null, "Building stuff with care"), "\u201D is the best way I have got to help people."), ___EmotionJSX(AboutMe.Hobbies, null, ___EmotionJSX(AboutMe.BookIcon, null), ___EmotionJSX(AboutMe.DumbellIcon, null), ___EmotionJSX(AboutMe.JoystickIcon, null), ___EmotionJSX(AboutMe.HeadphonesIcon, null)), ___EmotionJSX(Parragraph, {
    marginBottom: 0
  }, "I am always hungry for knowledge, you will always see me reading and sharing articles, tweets and listening to podcasts related to software development. I also love training myself and playing videogames from time to time.")));
}