import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function SketchIcon() {
  return ___EmotionJSX("svg", {
    width: "147",
    height: "133",
    viewBox: "0 0 147 133",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-683 -608)"
  }, ___EmotionJSX("g", {
    transform: "translate(683 608)"
  }, ___EmotionJSX("g", {
    fillRule: "nonzero"
  }, ___EmotionJSX("polygon", {
    fill: "#EDEDED",
    points: "73.5 0 32.0729883 4.3793285 0 47.4256935 73.5 133 147 47.4256935 114.927586 4.3793285"
  }), ___EmotionJSX("polygon", {
    fill: "#EDEDED",
    points: "0 48 74 133 29.9734687 48"
  }), ___EmotionJSX("polygon", {
    fill: "#EDEDED",
    points: "117.026297 48 73 133 147 48"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "31 48 74.5 133 118 48"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "33.2636631 4.43236045 31 48 74 0"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "116 48 113.736872 4.43177939 73 0"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "118.225069 48 147 48 116 6"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "0 48 28.7749709 48 31 6"
  }), ___EmotionJSX("polygon", {
    fill: "#EDEDED",
    points: "74.5 0 31 48 118 48"
  }))))));
}