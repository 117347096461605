import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { space, breakpoints } from '../../../@theme';
export var JobDetails = _styled("div", {
  target: "etpgay60"
})("display:flex;flex-direction:column;align-items:center;margin-top:", space(5), "px;opacity:", function (_ref) {
  var variant = _ref.variant;

  switch (variant) {
    case 'present':
      return 1;

    default:
      return 0.4;
  }
}, ";", breakpoints.lg, "{margin-top:unset;}");
JobDetails.propTypes = {
  variant: PropTypes.string
};
JobDetails.defaultProps = {
  variant: 'past'
};