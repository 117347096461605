import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
export function IdeawareLogo(props) {
  return ___EmotionJSX("svg", _extends({
    width: "69",
    height: "53",
    viewBox: "0 0 69 53",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-834 -120)",
    fill: "#00C3FF",
    fillRule: "nonzero"
  }, ___EmotionJSX("g", {
    transform: "translate(834 120)"
  }, ___EmotionJSX("g", null, ___EmotionJSX("path", {
    d: "M13.0736842,6.44594595 C13.0736842,10.0059436 10.1470403,12.8918919 6.53684211,12.8918919 C2.9266439,12.8918919 0,10.0059436 0,6.44594595 C0,2.8859483 2.9266439,0 6.53684211,0 C8.27052042,0 9.93319179,0.679124498 11.1590875,1.88797386 C12.3849832,3.09682321 13.0736842,4.73637479 13.0736842,6.44594595 Z"
  }), ___EmotionJSX("path", {
    d: "M20.7798947,30.3675676 L25.1377895,16.2437838 C26.3733383,12.2478141 30.1084657,9.51460946 34.3449493,9.50638265 C38.5814328,9.49815584 42.3274479,12.2168328 43.5789474,16.207973 L48.0312632,30.3031081 L56.0207368,4.47635135 C57.0509747,1.51930179 60.0847198,-0.282260539 63.217109,0.202850245 C66.3494982,0.687961029 68.6771394,3.31983727 68.7385263,6.44594595 C68.7376234,6.98219633 68.669313,7.51626845 68.5351579,8.03594595 L56.6889474,46.1816216 C55.5655949,49.9933989 52.0240266,52.6153048 47.9985789,52.6153048 C43.9731313,52.6153048 40.431563,49.9933989 39.3082105,46.1816216 L34.3837895,30.3675676 L29.4448421,46.2532432 C28.2927281,50.0569996 24.7310527,52.652979 20.7053925,52.6233742 C16.6797323,52.5932595 13.158062,49.9447406 12.0641053,46.1243243 L5.74515789,25.7837838 C5.54764625,25.1652217 5.44961589,24.5199679 5.45444843,23.8714865 C5.51982108,20.7253018 7.87922152,18.0853813 11.0362819,17.6262078 C14.1933423,17.1670343 17.2251863,19.0228341 18.2087368,22.0164865 L20.7798947,30.3675676 Z"
  }))))));
}