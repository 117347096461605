import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { COLORS, space, breakpoints } from '../../../@theme';
export var Greeting = _styled("h1", {
  target: "e1p1qcci0"
})("line-height:1.5;font-weight:normal;font-size:", space(6), "px;", function (_ref) {
  var color = _ref.color;
  return color && "color: " + color;
}, ";", function (_ref2) {
  var align = _ref2.align;
  return align && "text-align: " + align;
}, ";", function (_ref3) {
  var margin = _ref3.margin;
  return margin && "margin: " + margin;
}, ";", function (_ref4) {
  var maxWidth = _ref4.maxWidth;
  return maxWidth ? "max-width: " + maxWidth + "px" : 'max-width: auto';
}, ";position:relative;", breakpoints.md, "{font-size:", space(8), "px;}", breakpoints.lg, "{font-size:", space(8), "px;}", breakpoints.xl, "{font-size:", space(10), "px;}", breakpoints.uw, "{font-size:", space(12), "px;}", breakpoints.t1, "{text-align:center;}", breakpoints.t3, "{text-align:center;font-size:", space(10), "px;}", breakpoints.t4, "{font-size:", space(11), "px;}");
Greeting.defaultProps = {
  color: COLORS.FADED_WHITE,
  align: 'center',
  margin: '0 auto',
  maxWidth: 480
};
Greeting.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  maxWidth: PropTypes.number,
  margin: PropTypes.string
};