import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { COLORS } from '../../../@theme';
export var TimeSegment = _styled("div", {
  target: "e196hijw0"
})("width:5px;background-color:", COLORS.PRIMARY, ";height:100%;", function (_ref) {
  var variant = _ref.variant;

  switch (variant) {
    case 'beggining':
      return "\n      border-top-left-radius: 5px;\n      border-top-right-radius: 5px;\n      ";

    case 'present':
      return "\n        height: 50%;\n        position: relative;\n        border-bottom-left-radius: 5px;\n        border-bottom-right-radius: 5px;\n        bottom: 50px;\n      ";

    default:
      return '';
  }
});
TimeSegment.propTypes = {
  variant: PropTypes.string
};
TimeSegment.defaultProps = {
  variant: 'past'
};