import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function SmileIcon() {
  return ___EmotionJSX("svg", {
    width: "99",
    height: "99",
    viewBox: "0 0 99 99",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-408 -259)",
    fill: "#6B34FB"
  }, ___EmotionJSX("g", {
    transform: "translate(286 256)"
  }, ___EmotionJSX("g", {
    transform: "translate(23 3)"
  }, ___EmotionJSX("g", {
    transform: "translate(98)"
  }, ___EmotionJSX("circle", {
    cx: "32.711",
    cy: "36.444",
    r: "4.444"
  }), ___EmotionJSX("circle", {
    cx: "68.267",
    cy: "36.444",
    r: "4.444"
  }), ___EmotionJSX("path", {
    d: "M50.4888889,0.888888889 C23.4883011,0.888888889 1.6,22.77719 1.6,49.7777778 C1.6,76.7783655 23.4883011,98.6666667 50.4888889,98.6666667 C77.4894767,98.6666667 99.3777778,76.7783655 99.3777778,49.7777778 C99.3777778,22.77719 77.4894767,0.888888889 50.4888889,0.888888889 L50.4888889,0.888888889 Z M50.4888889,92 C27.1701995,92 8.26666667,73.0964672 8.26666667,49.7777778 C8.26666667,26.4590883 27.1701995,7.55555556 50.4888889,7.55555556 C73.8075783,7.55555556 92.7111111,26.4590883 92.7111111,49.7777778 C92.7111111,73.0964672 73.8075783,92 50.4888889,92 L50.4888889,92 Z"
  }), ___EmotionJSX("path", {
    d: "M50.4888889,78.6666667 C39.2740115,78.3050586 29.0077259,72.2808324 23.2222222,62.6666667 C22.2403827,61.1079964 22.7079964,59.0485062 24.2666667,58.0666667 C25.825337,57.0848271 27.8848271,57.5524408 28.8666667,59.1111111 C33.1590965,67.0537165 41.4606037,72.0035705 50.4888889,72.0035705 C59.5171741,72.0035705 67.8186812,67.0537165 72.1111111,59.1111111 C72.7462498,58.1028279 73.8710549,57.5099784 75.0618231,57.5558832 C76.2525913,57.6017881 77.3284165,58.2794733 77.8840457,59.3336612 C78.4396748,60.3878492 78.3906947,61.6583838 77.7555556,62.6666667 C71.9700519,72.2808324 61.7037663,78.3050586 50.4888889,78.6666667 Z"
  })))))));
}