import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function TypeScriptIcon() {
  return ___EmotionJSX("svg", {
    width: "132",
    height: "132",
    viewBox: "0 0 132 132",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-910 -543)"
  }, ___EmotionJSX("g", {
    transform: "translate(910 543)"
  }, ___EmotionJSX("g", {
    fillRule: "nonzero"
  }, ___EmotionJSX("polygon", {
    fill: "#FFF",
    transform: "matrix(1 0 0 -1 0 132)",
    points: "0 66 0 0 66 0 132 0 132 66 132 132 66 132 0 132"
  }), ___EmotionJSX("path", {
    d: "M75.9576581,115.517594 L76,110.082687 L67.31982,110.082687 L58.63964,110.082687 L58.63964,85.5413432 L58.63964,61 L52.5,61 L46.3603605,61 L46.3603605,85.5413432 L46.3603605,110.082687 L37.6801802,110.082687 L29,110.082687 L29,115.412266 C29,118.36144 29.0635135,120.826107 29.1481982,120.889304 C29.2117118,120.973566 39.7761262,121.015697 52.5846847,120.994632 L75.8941445,120.931435 L75.9576581,115.517594 Z",
    fill: "#FB3455",
    transform: "rotate(-180 52.5 91)"
  }), ___EmotionJSX("path", {
    d: "M106.735126,120.456542 C110.116658,119.618221 112.695076,118.130202 115.062148,115.699072 C116.287954,114.399675 118.105527,112.031418 118.253469,111.465552 C118.295738,111.297887 112.504865,107.441612 108.996526,105.282936 C108.869718,105.199103 108.362489,105.744012 107.791855,106.582333 C106.079955,109.055379 104.283516,110.124238 101.536022,110.31286 C97.4993183,110.585315 94.8997659,108.489513 94.9209006,104.989523 C94.9209006,103.96258 95.0688423,103.354798 95.4915341,102.516477 C96.3791858,100.693129 98.0276826,99.6033124 103.205653,97.381762 C112.737345,93.3159059 116.816318,90.6332798 119.352467,86.8189199 C122.184499,82.5644418 122.818537,75.7740434 120.89529,70.7231605 C118.781833,65.2321594 113.540459,61.5016318 106.164493,60.2651087 C103.881959,59.8669061 98.4715084,59.9297803 96.0198984,60.3698985 C90.6728514,61.3130097 85.6005539,63.9327622 82.4726373,67.3698775 C81.2468317,68.7111908 78.858625,72.2111798 79.0065673,72.462676 C79.0699706,72.5465082 79.6194696,72.8818367 80.2323723,73.2381227 C80.8241405,73.5734512 83.0644049,74.8518903 85.1778622,76.0674558 L89.00322,78.2680475 L89.8063338,77.0943987 C90.9264663,75.3967989 93.3780768,73.0704587 94.8574967,72.295012 C99.1055458,70.0734617 104.938688,70.3878321 107.81299,72.9447109 C109.038795,74.0554855 109.546025,75.2081768 109.546025,76.9057767 C109.546025,78.435712 109.355814,79.1063684 108.5527,80.2590597 C107.517106,81.7261209 105.403648,82.9626444 99.4014299,85.5404808 C92.5326938,88.4746036 89.5738536,90.2979513 86.8686282,93.1901581 C85.3046698,94.8667999 83.8252495,97.549426 83.2123472,99.7919344 C82.7051175,101.657198 82.5783098,106.330837 82.979867,108.217058 C84.395883,114.797877 89.4047767,119.387683 96.6328007,120.749955 C98.9787387,121.190073 104.431458,121.022408 106.735126,120.456542 Z",
    fill: "#FB3455",
    transform: "matrix(1 0 0 -1 0 181)"
  }))))));
}