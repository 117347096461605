import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { Contact, LinkedInIcon, GithubIcon, TwitterIcon } from '../../@components/@atoms';
export function ContactMe(_ref) {
  var appBarVariant = _ref.appBarVariant;
  return ___EmotionJSX(Contact.Section, {
    id: "contact-me",
    appBarVariant: appBarVariant
  }, ___EmotionJSX(Contact.Title, null, "Wanna contact me?"), ___EmotionJSX(Contact.Message, null, "Feel free to contact me using any of these methods"), ___EmotionJSX(Contact.List, null, ___EmotionJSX(Contact.ListItem, null, ___EmotionJSX("h3", {
    hidden: true
  }, "My Email is", ___EmotionJSX("a", {
    href: "https://www.linkedin.com/in/carlos-camilo-lobo/"
  }, "https://www.linkedin.com/in/carlos-camilo-lobo/")), ___EmotionJSX("a", {
    href: "https://www.linkedin.com/in/carlos-camilo-lobo/"
  }, ___EmotionJSX(LinkedInIcon, null))), ___EmotionJSX(Contact.ListItem, null, ___EmotionJSX("h3", {
    hidden: true
  }, "My github is ", ___EmotionJSX("a", {
    href: "https://github.com/Platekun"
  }, "Platekun")), ___EmotionJSX("a", {
    href: "https://github.com/Platekun"
  }, ___EmotionJSX(GithubIcon, null))), ___EmotionJSX(Contact.ListItem, null, ___EmotionJSX("h3", {
    hidden: true
  }, "My Twitter is", ' ', ___EmotionJSX("a", {
    href: "https://twitter.com/_CamiloLobo"
  }, "_CamiloLobo")), ___EmotionJSX("a", {
    href: "https://twitter.com/_CamiloLobo"
  }, ___EmotionJSX(TwitterIcon, null)))));
}