import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function IdeaIcon() {
  return ___EmotionJSX("svg", {
    width: "99",
    height: "98",
    viewBox: "0 0 99 98",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-673 -477)",
    fill: "#6B34FB"
  }, ___EmotionJSX("g", {
    transform: "translate(286 256)"
  }, ___EmotionJSX("g", {
    transform: "translate(296 220)"
  }, ___EmotionJSX("g", {
    transform: "translate(91)"
  }, ___EmotionJSX("path", {
    d: "M48.3043478,12.5531915 C50.1052764,12.5531915 51.5652174,11.124313 51.5652174,9.36170213 L51.5652174,5.10638298 C51.5652174,3.34377208 50.1052764,1.91489362 48.3043478,1.91489362 C46.5034193,1.91489362 45.0434783,3.34377208 45.0434783,5.10638298 L45.0434783,9.36170213 C45.0434783,11.124313 46.5034193,12.5531915 48.3043478,12.5531915 Z"
  }), ___EmotionJSX("path", {
    d: "M95.0434783,41.2765957 L90.6956522,41.2765957 C88.8947236,41.2765957 87.4347826,42.7054742 87.4347826,44.4680851 C87.4347826,46.230696 88.8947236,47.6595745 90.6956522,47.6595745 L95.0434783,47.6595745 C96.8444068,47.6595745 98.3043478,46.230696 98.3043478,44.4680851 C98.3043478,42.7054742 96.8444068,41.2765957 95.0434783,41.2765957 Z"
  }), ___EmotionJSX("path", {
    d: "M11.3478261,44.4680851 C11.3478261,42.7054742 9.88788505,41.2765957 8.08695652,41.2765957 L3.73913043,41.2765957 C1.9382019,41.2765957 0.47826087,42.7054742 0.47826087,44.4680851 C0.47826087,46.230696 1.9382019,47.6595745 3.73913043,47.6595745 L8.08695652,47.6595745 C9.88788505,47.6595745 11.3478261,46.230696 11.3478261,44.4680851 Z"
  }), ___EmotionJSX("path", {
    d: "M78.5869565,23.3191489 C79.4515015,23.318404 80.2803593,22.9816702 80.8913043,22.3829787 L83.9782609,19.3829787 C85.1754733,18.1254904 85.1401473,16.1659161 83.8983556,14.9505455 C82.656564,13.735175 80.6543902,13.7006006 79.3695652,14.8723404 L76.2826087,17.8723404 C75.3512161,18.7850503 75.0727905,20.1567748 75.5770131,21.3486107 C76.0812358,22.5404466 77.2689493,23.3180143 78.5869565,23.3191489 Z"
  }), ___EmotionJSX("path", {
    d: "M20.326087,17.8723404 L17.2391304,14.8723404 C15.9543054,13.7006006 13.9521316,13.735175 12.71034,14.9505455 C11.4685484,16.1659161 11.4332223,18.1254904 12.6304348,19.3829787 L15.7173913,22.3829787 C17.0022163,23.5547186 19.0043901,23.5201442 20.2461817,22.3047736 C21.4879734,21.0894031 21.5232994,19.1298287 20.326087,17.8723404 L20.326087,17.8723404 Z"
  }), ___EmotionJSX("path", {
    d: "M85.2608696,68.9361702 C83.9760445,67.7644304 81.9738708,67.7990048 80.7320791,69.0143753 C79.4902875,70.2297459 79.4549615,72.1893202 80.6521739,73.4468085 L83.7391304,76.4468085 C85.0239555,77.6185483 87.0261292,77.5839739 88.2679209,76.3686034 C89.5097125,75.1532329 89.5450385,73.1936585 88.3478261,71.9361702 L85.2608696,68.9361702 Z"
  }), ___EmotionJSX("path", {
    d: "M11.3478261,68.9361702 L8.26086957,71.9361702 C7.06365713,73.1936585 7.09898315,75.1532329 8.34077479,76.3686034 C9.58256643,77.5839739 11.5847402,77.6185483 12.8695652,76.4468085 L15.9565217,73.4468085 C17.1537342,72.1893202 17.1184082,70.2297459 15.8766165,69.0143753 C14.6348249,67.7990048 12.6326511,67.7644304 11.3478261,68.9361702 Z"
  }), ___EmotionJSX("path", {
    d: "M79.826087,49.787234 C79.84698,35.1734346 69.0328039,22.7030437 54.2965027,20.3477378 C39.5602016,17.992432 25.2544443,26.4478993 20.5241624,40.3089962 C15.7938805,54.170093 22.0362597,69.3426603 35.2608696,76.1276596 C34.5828228,77.155023 34.2206299,78.3514156 34.2173913,79.5744681 L34.2173913,85.9574468 C34.2173913,93.0078904 40.0571554,98.7234043 47.2608696,98.7234043 L51.6086957,98.7234043 C58.8124098,98.7234043 64.6521739,93.0078904 64.6521739,85.9574468 L64.6521739,79.5744681 C64.6489353,78.3514156 64.2867424,77.155023 63.6086957,76.1276596 C73.5855245,70.9687181 79.8272589,60.8308398 79.826087,49.787234 Z M58.0869565,79.5744681 L58.0869565,81.7021277 L47.2173913,81.7021277 C45.4164628,81.7021277 43.9565217,83.1310061 43.9565217,84.893617 C43.9565217,86.6562279 45.4164628,88.0851064 47.2173913,88.0851064 L57.6956522,88.0851064 C56.7766341,90.62921 54.3222933,92.3328418 51.5652174,92.3404255 L47.2173913,92.3404255 C43.6155342,92.3404255 40.6956522,89.4826686 40.6956522,85.9574468 L40.6956522,79.5744681 L58.0869565,79.5744681 Z M49.3913043,73.1914894 L49.3913043,73.1914894 C36.1844951,73.1914894 25.4782609,62.7130473 25.4782609,49.787234 C25.4782609,36.8614207 36.1844951,26.3829787 49.3913043,26.3829787 C62.5981136,26.3829787 73.3043478,36.8614207 73.3043478,49.787234 C73.3043504,55.9981166 70.7819638,61.9543072 66.2926954,66.3440686 C61.8034269,70.7338301 55.7154644,73.1971356 49.3695652,73.1914894 L49.3913043,73.1914894 Z"
  }), ___EmotionJSX("path", {
    d: "M49.3913043,31.7021277 C47.5903758,31.7021277 46.1304348,33.1310061 46.1304348,34.893617 C46.1304348,36.6562279 47.5903758,38.0851064 49.3913043,38.0851064 C55.9897467,38.0968164 61.3358615,43.3291841 61.3478261,49.787234 C61.3478261,51.5498449 62.8077671,52.9787234 64.6086957,52.9787234 C66.4096242,52.9787234 67.8695652,51.5498449 67.8695652,49.787234 C67.857586,39.803966 59.5916,31.713852 49.3913043,31.7021277 L49.3913043,31.7021277 Z"
  })))))));
}