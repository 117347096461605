var PRIMARY = '#FB3455';
var ACCENT = '#6B34FB';
var TEXT_PRIMARY = '#16161D';
var WHITE = 'white';
var FADED_WHITE = 'rgba(255, 255, 255, 0.5)';
var GRAY = '#fef';
export var COLORS = {
  PRIMARY: PRIMARY,
  ACCENT: ACCENT,
  TEXT_PRIMARY: TEXT_PRIMARY,
  WHITE: WHITE,
  FADED_WHITE: FADED_WHITE,
  GRAY: GRAY
};