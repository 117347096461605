import { jsx as ___EmotionJSX } from "@emotion/core";
import staticQueryData from "../../public/static/d/4051563084.json";

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import Helmet from 'react-helmet';
export function SEO() {
  var _staticQueryData$data = staticQueryData.data.site.siteMetadata,
      title = _staticQueryData$data.title,
      description = _staticQueryData$data.description,
      author = _staticQueryData$data.author,
      lang = _staticQueryData$data.lang,
      keywords = _staticQueryData$data.keywords,
      twitterCreator = _staticQueryData$data.twitterCreator;
  return ___EmotionJSX(Helmet, {
    htmlAttributes: {
      lang: lang
    },
    title: title,
    meta: [{
      property: 'og:title',
      content: title
    }, {
      name: 'author',
      content: author
    }, {
      name: 'description',
      content: description
    }, {
      property: 'og:description',
      content: description
    }, {
      property: 'og:type',
      content: 'website'
    }, {
      name: 'twitter:card',
      content: 'summary'
    }, {
      name: 'twitter:creator',
      content: twitterCreator
    }, {
      name: 'twitter:title',
      content: title
    }, {
      name: 'twitter:description',
      content: description
    }].concat(keywords.length > 0 ? {
      name: 'keywords',
      content: keywords.join(', ')
    } : [])
  });
}