import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { space, breakpoints } from '../../../@theme';
export var Hobbies = _styled("div", {
  target: "e1wuqva50"
})("width:100%;max-width:320px;margin-top:", function (_ref) {
  var marginTop = _ref.marginTop;
  return marginTop && space(marginTop) + "px";
}, ";margin-bottom:", function (_ref2) {
  var marginBottom = _ref2.marginBottom;
  return marginBottom && space(marginBottom) + "px";
}, ";margin-right:auto;margin-left:auto;display:flex;align-items:center;justify-content:space-between;", breakpoints.md, "{max-width:519px;}", breakpoints.xl, "{margin:", space(16), "px auto;}");
Hobbies.defaultProps = {
  marginBottom: 8,
  marginTop: 8
};
Hobbies.propTypes = {
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number
};