import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function StarsIcon() {
  return ___EmotionJSX("svg", {
    width: "99",
    height: "98",
    viewBox: "0 0 99 98",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-425 -721)",
    fill: "#6B34FB"
  }, ___EmotionJSX("g", {
    transform: "translate(286 256)"
  }, ___EmotionJSX("g", {
    transform: "translate(0 464)"
  }, ___EmotionJSX("g", {
    transform: "translate(138)"
  }, ___EmotionJSX("path", {
    d: "M76.8,38.5 C76.8025215,35.7462667 75.2957649,33.2126123 72.875,31.9 C55.85,22.7 55.1,21.9 45.775,4.9 C44.4600653,2.49676795 41.9394479,1.00164893 39.2,1 L39.2,1 C36.4498926,1.01160973 33.9267046,2.52744133 32.625,4.95 C23.475,21.925 22.625,22.675 5.75,31.925 C3.33718189,33.2382196 1.83536522,35.7654585 1.83536522,38.5125 C1.83536522,41.2595415 3.33718189,43.7867804 5.75,45.1 C22.75,54.325 23.5,55.1 32.725,72.1 C34.0382196,74.5128181 36.5654585,76.0146348 39.3125,76.0146348 C42.0595415,76.0146348 44.5867804,74.5128181 45.9,72.1 C55.125,55.1 55.9,54.35 72.875,45.125 C75.3036729,43.8081304 76.8117259,41.2626907 76.8,38.5 Z M39.3,68.5 C29.3,50.225 27.55,48.5 9.3,38.5 C27.5,28.5 29.3,26.75 39.3,8.5 C49.3,26.775 51.075,28.5 69.3,38.5 C51.05,48.5 49.2,50.25 39.3,68.5 Z"
  }), ___EmotionJSX("path", {
    d: "M95.375,71.9 C89.225,68.575 89.225,68.575 85.9,62.425 C84.5867804,60.0121819 82.0595415,58.5103652 79.3125,58.5103652 C76.5654585,58.5103652 74.0382196,60.0121819 72.725,62.425 C69.4,68.575 69.4,68.575 63.25,71.9 C60.8371819,73.2132196 59.3353652,75.7404585 59.3353652,78.4875 C59.3353652,81.2345415 60.8371819,83.7617804 63.25,85.075 C69.4,88.4 69.4,88.4 72.725,94.55 C74.0382196,96.9628181 76.5654585,98.4646348 79.3125,98.4646348 C82.0595415,98.4646348 84.5867804,96.9628181 85.9,94.55 C89.225,88.4 89.225,88.4 95.375,85.075 C97.7878181,83.7617804 99.2896348,81.2345415 99.2896348,78.4875 C99.2896348,75.7404585 97.7878181,73.2132196 95.375,71.9 Z M79.3,91 C75.175,83.5 74.3,82.625 66.8,78.5 C74.3,74.375 75.175,73.5 79.3,66 C83.425,73.5 84.3,74.375 91.8,78.5 C84.2,82.625 83.425,83.5 79.3,91 Z"
  })))))));
}