import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { DesktopHero } from './Hero.desktop';
import { MobileHero } from './Hero.mobile';
import { CoreValuesMobile } from './CoreValues.mobile';
import { Hero, HiddenUpLg, HiddenDownLg } from '../../@components/@atoms';
export function HeroComponent(_ref) {
  var appBarVariant = _ref.appBarVariant;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Hero.SectionLayout, {
    id: "top",
    appBarVariant: appBarVariant
  }, ___EmotionJSX(HiddenUpLg, null, ___EmotionJSX(MobileHero, {
    appBarVariant: appBarVariant
  })), ___EmotionJSX(HiddenDownLg, null, ___EmotionJSX(DesktopHero, null))), ___EmotionJSX(HiddenUpLg, null, ___EmotionJSX(CoreValuesMobile, null)));
}