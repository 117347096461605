import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { Section, SectionTitle, HiddenUpMd, HiddenDownMd } from '../../@components/@atoms';
import { MobileWorkExperience } from './WorkExperience.mobile';
import { DesktopWorkExperience } from './WorkExperience.desktop';
export function WorkExperience(_ref) {
  var appBarVariant = _ref.appBarVariant;
  return ___EmotionJSX(Section, {
    id: "work-experience",
    appBarVariant: appBarVariant
  }, ___EmotionJSX(SectionTitle, null, "Work Experience"), ___EmotionJSX(HiddenUpMd, null, ___EmotionJSX(MobileWorkExperience, {
    appBarVariant: appBarVariant
  })), ___EmotionJSX(HiddenDownMd, null, ___EmotionJSX(DesktopWorkExperience, null)));
}