import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { IdeawareLogo, WorkExperience } from '../../@components/@atoms';
import { PastTicomLogo, PastNativAppsLogo, PastFslLogo } from './past-logos';
export function DesktopWorkExperience() {
  return ___EmotionJSX(WorkExperience.Jobs, null, ___EmotionJSX(WorkExperience.Entry, null, ___EmotionJSX(WorkExperience.JobLogoContainer, {
    href: "http://ticom.co/wordpress/la-empresa"
  }, ___EmotionJSX(PastTicomLogo, null)), ___EmotionJSX(WorkExperience.TimeSegment, {
    variant: "beggining"
  }, ___EmotionJSX(WorkExperience.TimelineEvent, null)), ___EmotionJSX(WorkExperience.JobDetails, null, ___EmotionJSX("h3", {
    hidden: true
  }, "Worked at TICOM S.A"), ___EmotionJSX(WorkExperience.JobTitle, null, "Full Stack Developer"), ___EmotionJSX(WorkExperience.EmploymentPeriod, null, "Jan 2016 - Jul 2016"))), ___EmotionJSX(WorkExperience.Entry, null, ___EmotionJSX(WorkExperience.JobLogoContainer, {
    href: "https://nativapps.com/"
  }, ___EmotionJSX(PastNativAppsLogo, null)), ___EmotionJSX(WorkExperience.TimeSegment, null, ___EmotionJSX(WorkExperience.TimelineEvent, null)), ___EmotionJSX(WorkExperience.JobDetails, null, ___EmotionJSX("h3", {
    hidden: true
  }, "Worked at NativApps S.A.S"), ___EmotionJSX(WorkExperience.JobTitle, null, "Back-End Developer"), ___EmotionJSX(WorkExperience.JobTitle, null, "Front-End Developer"), ___EmotionJSX(WorkExperience.EmploymentPeriod, null, "Feb 2017 - Mar 2018"))), ___EmotionJSX(WorkExperience.Entry, null, ___EmotionJSX(WorkExperience.JobLogoContainer, {
    href: "https://fullstacklabs.co/"
  }, ___EmotionJSX(PastFslLogo, null)), ___EmotionJSX(WorkExperience.TimeSegment, null, ___EmotionJSX(WorkExperience.TimelineEvent, null)), ___EmotionJSX(WorkExperience.JobDetails, null, ___EmotionJSX("h3", {
    hidden: true
  }, "Worked at FullStack Labs"), ___EmotionJSX(WorkExperience.JobTitle, null, "Front-End Developer"), ___EmotionJSX(WorkExperience.EmploymentPeriod, null, "Mar 2018 - Jun 2018"))), ___EmotionJSX(WorkExperience.Entry, null, ___EmotionJSX(WorkExperience.JobLogoContainer, {
    href: "http://ideaware.co"
  }, ___EmotionJSX(WorkExperience.CurrentJobLogo, null, ___EmotionJSX(IdeawareLogo, null))), ___EmotionJSX(WorkExperience.TimeSegment, {
    variant: "present"
  }, ___EmotionJSX(WorkExperience.TimelineEvent, {
    variant: "present"
  })), ___EmotionJSX(WorkExperience.JobDetails, {
    variant: "present"
  }, ___EmotionJSX("h3", {
    hidden: true
  }, "Currently working at Ideaware"), ___EmotionJSX(WorkExperience.JobTitle, null, "Front-End Developer"), ___EmotionJSX(WorkExperience.EmploymentPeriod, null, "Jul 2018 - Present"))));
}