import { jsx as ___EmotionJSX } from "@emotion/core";
import React from "react";
import PropTypes from "prop-types";
import Link, { withPrefix, withAssetPrefix, navigate, push, replace, navigateTo, parsePath } from "gatsby-link";
import PageRenderer from "./public-page-renderer";
var StaticQueryContext = React.createContext({});

function StaticQueryDataRenderer(_ref) {
  var staticQueryData = _ref.staticQueryData,
      data = _ref.data,
      query = _ref.query,
      render = _ref.render;
  var finalData = data ? data.data : staticQueryData[query] && staticQueryData[query].data;
  return ___EmotionJSX(React.Fragment, null, finalData && render(finalData), !finalData && ___EmotionJSX("div", null, "Loading (StaticQuery)"));
}

var StaticQuery = function StaticQuery(props) {
  var data = props.data,
      query = props.query,
      render = props.render,
      children = props.children;
  return ___EmotionJSX(StaticQueryContext.Consumer, null, function (staticQueryData) {
    return ___EmotionJSX(StaticQueryDataRenderer, {
      data: data,
      query: query,
      render: render || children,
      staticQueryData: staticQueryData
    });
  });
};

var useStaticQuery = function useStaticQuery(query) {
  if (typeof React.useContext !== "function" && process.env.NODE_ENV === "development") {
    throw new Error("You're likely using a version of React that doesn't support Hooks\n" + "Please update React and ReactDOM to 16.8.0 or later to use the useStaticQuery hook.");
  }

  var context = React.useContext(StaticQueryContext);

  if (context[query] && context[query].data) {
    return context[query].data;
  } else {
    throw new Error("The result of this StaticQuery could not be fetched.\n\n" + "This is likely a bug in Gatsby and if refreshing the page does not fix it, " + "please open an issue in https://github.com/gatsbyjs/gatsby/issues");
  }
};

StaticQuery.propTypes = {
  data: PropTypes.object,
  query: PropTypes.string.isRequired,
  render: PropTypes.func,
  children: PropTypes.func
};

function graphql() {
  throw new Error("It appears like Gatsby is misconfigured. Gatsby related `graphql` calls " + "are supposed to only be evaluated at compile time, and then compiled away. " + "Unfortunately, something went wrong and the query was left in the compiled code.\n\n" + "Unless your site has a complex or custom babel/Gatsby configuration this is likely a bug in Gatsby.");
}

export { Link, withAssetPrefix, withPrefix, graphql, parsePath, navigate, push, // TODO replace for v3
replace, // TODO remove replace for v3
navigateTo, // TODO: remove navigateTo for v3
StaticQueryContext, StaticQuery, PageRenderer, useStaticQuery };