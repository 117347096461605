import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { HTML5Icon, CSS3Icon, TypeScriptIcon, NodejsIcon, SketchIcon, ReactIcon, Tools } from '../../@components/@atoms';
export function FavoriteTools(_ref) {
  var appBarVariant = _ref.appBarVariant;
  return ___EmotionJSX(Tools.Section, {
    id: "tools",
    appBarVariant: appBarVariant
  }, ___EmotionJSX(Tools.SectionContent, null, ___EmotionJSX(Tools.Title, null, "Favorite Tools"), ___EmotionJSX(Tools.List, null, ___EmotionJSX(Tools.ListItem, null, ___EmotionJSX("a", {
    href: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
  }, ___EmotionJSX(HTML5Icon, null)), ___EmotionJSX("h3", {
    hidden: true
  }, "HTML5")), ___EmotionJSX(Tools.ListItem, null, ___EmotionJSX("a", {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3"
  }, ___EmotionJSX(CSS3Icon, null)), ___EmotionJSX("h3", {
    hidden: true
  }, "CSS3")), ___EmotionJSX(Tools.ListItem, null, ___EmotionJSX("a", {
    href: "https://reactjs.org/"
  }, ___EmotionJSX(ReactIcon, null)), ___EmotionJSX("h3", {
    hidden: true
  }, "React.js")), ___EmotionJSX(Tools.ListItem, null, ___EmotionJSX("a", {
    href: "https://www.sketch.com/"
  }, ___EmotionJSX(SketchIcon, null)), ___EmotionJSX("h3", {
    hidden: true
  }, "Sketch")), ___EmotionJSX(Tools.ListItem, null, ___EmotionJSX("a", {
    href: "https://www.typescriptlang.org/"
  }, ___EmotionJSX(TypeScriptIcon, null)), ___EmotionJSX("h3", {
    hidden: true
  }, "TypeScript")), ___EmotionJSX(Tools.ListItemWithLargeLogo, null, ___EmotionJSX("a", {
    href: "https://nodejs.org"
  }, ___EmotionJSX(NodejsIcon, null)), ___EmotionJSX("h3", {
    hidden: true
  }, "Node.js")))));
}