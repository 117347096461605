import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function LinkedInIcon() {
  return ___EmotionJSX("svg", {
    width: "114",
    height: "111",
    viewBox: "0 0 114 111",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    id: "Personal-Page",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    id: "Contact-Desktop",
    transform: "translate(-502 -462)",
    fillRule: "nonzero"
  }, ___EmotionJSX("g", {
    id: "linkedin",
    transform: "translate(502 462)"
  }, ___EmotionJSX("path", {
    d: "M105.091987,0 L9.00040095,0 C4.40857667,0 0.678112031,3.57111175 0.678112031,7.97430482 L0.678112031,102.653329 C0.678112031,107.060363 4.40857667,110.636852 9.00040095,110.636852 L105.091987,110.636852 C109.691642,110.636852 113.439334,107.060363 113.439334,102.653329 L113.439334,7.97430482 C113.439334,3.57111175 109.691642,0 105.091987,0",
    id: "Path",
    fill: "#006087"
  }), ___EmotionJSX("path", {
    d: "M25.7616539,15.231775 C31.1099073,15.231775 35.4542863,19.4951354 35.4542863,24.7434712 C35.4542863,29.9956484 31.1099073,34.2605454 25.7616539,34.2605454 C20.4024382,34.2605454 16.0635407,29.9956484 16.0635407,24.7434712 C16.0635407,19.4951354 20.4024382,15.231775 25.7616539,15.231775 L25.7616539,15.231775 Z M17.3962973,41.4780634 L34.1207465,41.4780634 L34.1207465,94.2771968 L17.3962973,94.2771968 L17.3962973,41.4780634 Z",
    id: "Shape",
    fill: "#FFF"
  }), ___EmotionJSX("path", {
    d: "M44.610527,41.4780634 L60.6545032,41.4780634 L60.6545032,48.6917399 L60.8768902,48.6917399 C63.1085917,44.5413213 68.5649058,40.1642508 76.7008282,40.1642508 C93.6367021,40.1642508 96.7634341,51.099628 96.7634341,65.3156953 L96.7634341,94.2771968 L80.0507304,94.2771968 L80.0507304,68.6009952 C80.0507304,62.4752478 79.9356208,54.6000551 71.3596227,54.6000551 C62.6559873,54.6000551 61.3232307,61.2705354 61.3232307,68.1576795 L61.3232307,94.2771968 L44.610527,94.2771968 L44.610527,41.4780634",
    id: "Path",
    fill: "#FFF"
  })))));
}