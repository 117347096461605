import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
export function TicomLogo(props) {
  return ___EmotionJSX("svg", _extends({
    width: "285",
    height: "72",
    viewBox: "0 0 285 72",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-209 -160)"
  }, ___EmotionJSX("g", {
    transform: "translate(209 160)"
  }, ___EmotionJSX("g", {
    transform: "translate(229.268 16.8)"
  }, ___EmotionJSX("polygon", {
    fill: "#FDCC00",
    points: "0.66934475 0.313424038 18.8767929 0.313424038 27.9899551 18.5033604 37.9780228 0.313424038 55.7353961 0.313424038 55.7353961 37.1381409 41.835612 37.1381409 41.835612 22.1196513 33.6335158 37.1381409 22.5856459 37.1381409 15.1527024 21.3759704 15.1527024 37.1381409 0.66934475 37.1381409"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "3 3.31953265 17.5465819 3.31953265 28.2023704 22.878115 38.8372358 3 53.4247543 3 53.4247543 35.0187638 44.5640342 35.0187638 44.5640342 12.7956658 31.6758382 34.2598453 24.8019062 34.2598453 12.9009637 13.1320279 12.9009637 35.0187638 3 35.0187638"
  })), ___EmotionJSX("g", {
    id: "O",
    transform: "translate(183.26 17.18)"
  }, ___EmotionJSX("path", {
    d: "M5.67499982,1.13686838e-13 L37.2426452,1.13686838e-13 C39.0466173,0.722987127 40.6052646,1.69306586 41.918587,2.91023621 C43.2319093,4.12740656 44.259047,5.55380012 45,7.18941689 L45,29.2286931 C44.2457572,30.5890672 43.3797049,31.8497964 42.4018432,33.0108809 C41.4239816,34.1719654 40.3891095,35.1683385 39.2972269,36 C34.3070783,36.5676836 29.0758559,36.8515255 23.6035597,36.8515255 C18.1312636,36.8515255 12.7349099,36.5676836 7.41449884,36 C6.00778586,34.9546097 4.65897812,33.8424548 3.36807562,32.6635353 C2.07717312,31.4846159 0.954481246,30.3396685 -3.55271368e-15,29.2286931 L-3.55271368e-15,7.18941689 C0.676246865,5.41484718 1.43991827,3.98845362 2.29101423,2.91023621 C3.14211018,1.8320188 4.27010538,0.861940063 5.67499982,1.13686838e-13 Z",
    fill: "#FDCC00"
  }), ___EmotionJSX("path", {
    d: "M7.36057502,2.62169314 L35.9696717,2.62169314 C37.6045738,3.25186291 39.0171432,4.09740259 40.2073798,5.15831216 C41.3976164,6.21922174 42.3284898,7.46249437 43,8.88813007 L43,28.097996 C42.3164455,29.2837247 41.5315605,30.3826011 40.6453449,31.3946251 C39.7591293,32.4066492 38.8212464,33.2751075 37.8316961,34 L8.93704659,34 C7.66217204,33.0888173 6.4397758,32.1194413 5.26985787,31.0918719 C4.09993994,30.0643025 3.08246779,29.0663439 2.2174414,28.097996 L2.2174414,8.88813007 C2.83030979,7.34138026 3.52240921,6.09810763 4.29373967,5.15831216 C5.06507012,4.2185167 6.08734857,3.37297702 7.36057502,2.62169314 Z",
    fill: "#000086"
  }), ___EmotionJSX("path", {
    d: "M13.6962652,10.8695382 L30.8700186,10.6536708 C31.4614669,11.115317 32.0067483,11.6628435 32.5058627,12.2962502 C33.0049771,12.9296569 33.4270019,13.6097011 33.771937,14.3363827 L33.771937,22.4940503 C33.4516549,23.3472594 33.0296301,24.0765838 32.5058627,24.6820234 C31.9820953,25.287463 31.3609853,25.7639317 30.6425327,26.1114295 L14.5860028,26.1114295 C13.8825207,25.7233775 13.2545631,25.2469088 12.70213,24.6820234 C12.1496969,24.1171379 11.7168956,23.5089373 11.4037261,22.8574215 L11.4037261,13.8869676 L12.4325483,11.9764903 L13.6962652,10.8695382 Z",
    fill: "#FDCC00"
  }), ___EmotionJSX("path", {
    d: "M15.2485632,12.8212506 L29.0685133,12.6536708 C29.5444598,13.012051 29.983255,13.4371008 30.3848991,13.9288203 C30.7865431,14.4205397 31.1261522,14.9484642 31.4037261,15.5125939 L31.4037261,21.8454661 C31.1459907,22.5078201 30.8063817,23.0740013 30.3848991,23.5440097 C29.9634164,24.014018 29.4636009,24.383905 28.8854524,24.6536708 L15.9645469,24.6536708 C15.3984454,24.3524224 14.8931195,23.9825354 14.4485692,23.5440097 C14.0040188,23.1054839 13.6557378,22.6333322 13.4037261,22.1275545 L13.4037261,15.1637088 L14.2316331,13.6805877 L15.2485632,12.8212506 Z",
    fill: "#FFF"
  })), ___EmotionJSX("g", {
    transform: "translate(138 17.85)"
  }, ___EmotionJSX("path", {
    d: "M42.8522625,6.29230052 L42.8522625,16.0006053 L29.9044212,16.0006053 L29.9044212,11.982456 L15.7717554,11.982456 L13.8198756,13.6887055 L13.8198756,22.1398383 L15.2865763,23.982456 L28.839818,23.982456 L29.9044212,23.0850788 L29.9044212,19.2487291 L43.9527174,19.2487291 L43.9527174,28.65004 C42.8197978,30.3961582 41.5913215,31.9001441 40.2672884,33.1619976 C38.9432554,34.4238512 37.5379535,35.4299555 36.0513827,36.1803107 L8.34271888,36.1803107 C6.62764642,35.4067859 5.08394681,34.4006816 3.71162005,33.1619976 C2.33929329,31.9233137 1.10208661,30.4193278 4.58300065e-13,28.65004 L4.58300065e-13,7.36506154 C0.966079938,5.55906246 1.9283518,4.12399145 2.88681559,3.05984849 C3.84527939,1.99570554 5.09422196,0.97575604 6.63364333,2.30926389e-13 L36.0513827,2.30926389e-13 C37.7187039,0.673115139 39.1240058,1.53856587 40.2672884,2.59635218 C41.410571,3.6541385 42.272229,4.88612128 42.8522625,6.29230052 Z",
    id: "Path-19",
    fill: "#FDCC00"
  }), ___EmotionJSX("path", {
    d: "M1.88339653,7.78776846 C2.80535987,6.36866907 3.81908361,5.15013964 4.92456778,4.13218016 C6.03005194,3.11422067 7.16220805,2.4640932 8.3210361,2.18179775 L35.3360655,2.18179775 L41.1620789,7.122472 L40.8842816,13.7189755 L32.0465084,14.0459347 C32.2735382,12.9068573 32.2735382,12.0464158 32.0465084,11.4646102 C31.8194785,10.8828046 31.2909339,10.3887532 30.4608744,9.98245603 L14.8267295,9.98245603 C13.9578886,10.5264883 13.2794332,11.0205397 12.7913634,11.4646102 C12.3032935,11.9086807 11.9124944,12.3874909 11.6189658,12.9010408 L11.6189658,22.5155301 C11.9508872,22.9005362 12.3416864,23.3538365 12.7913634,23.8754311 C13.2410404,24.3970256 13.6907174,24.9186202 14.1403943,25.4402148 L29.1745057,25.4402148 C30.5988006,25.2021178 31.5561348,24.7161982 32.0465084,23.982456 C32.5368819,23.2487139 32.5368819,22.3021818 32.0465084,21.1428597 L41.1620789,21.1428597 L40.8842816,28.9900948 C40.3363883,29.6895806 39.6994298,30.3761305 38.9734059,31.0497444 C38.2473821,31.7233583 37.3855094,32.4274427 36.3877878,33.1619976 L8.03035801,33.3287853 C6.87760431,32.6641532 5.8423409,31.9044729 4.92456778,31.0497444 C4.00679466,30.1950159 3.09289758,29.1394293 2.18287655,27.8829848 L1.88339653,7.78776846 Z",
    fill: "#000086"
  })), ___EmotionJSX("g", {
    transform: "translate(81.634 17.568)"
  }, ___EmotionJSX("polygon", {
    fill: "#FDCC00",
    points: "4.54747351e-13 2.27373675e-13 54.1902891 2.27373675e-13 54.1902891 36.4631164 40.3167807 36.4631164 40.3167807 12.5309448 27.0951445 12.5309448 27.0951445 36.4631164 13.2906921 36.4631164 13.2906921 12.5309448 4.54747351e-13 12.5309448"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "1.59650652 1.95270483 38.1605396 1.95270483 38.1605396 10.4209378 24.3655942 10.4209378 24.3655942 33.4448033 15.2284702 33.4448033 15.2284702 10.4209378 2.35814491 10.4209378"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "42.3590371 2.46460344 51.3144308 2.46460344 51.3144308 33.4448033 42.3590371 33.4448033"
  })), ___EmotionJSX("g", null, ___EmotionJSX("polygon", {
    fill: "#FDCC00",
    points: "36.3869355 0 72.2222765 36 36.3869355 72 0 36"
  }), ___EmotionJSX("path", {
    stroke: "#979797"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "30.4333569 9.91935344 36.1111383 4.1060324 59.4855429 26.9951429 54.0239714 32.8140394 45.5540285 24.9244607 35.3270452 35.1847266 28.813838 29.3995749 39.287898 18.7470836"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "4.35001923 36 28.3779292 11.5069766 35.7795952 18.833988 25.456 29.2299264 32.6963382 36.6161067 26.9809873 43.0364002 19.223152 35.1847266 11.6707901 43.0364002"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "39.4295799 34.4899537 45.5433966 28.5746765 53.5575537 36 61.3888879 28.5746765 68.6833101 36 43.7676347 60.1745943 36.5897013 53.2060636 47.2359522 42.2699773"
  }), ___EmotionJSX("polygon", {
    fill: "#000086",
    points: "13.7245999 45.1852038 36.1111383 67.4875069 41.9229964 61.9755493 33.3499702 53.0754759 44.1496905 43.0364002 37.6295494 36.4318971 27.1136552 47.0400117 19.1833979 39.5260172"
  }))))));
}