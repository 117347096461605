import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { COLORS } from '../../../@theme';
export var TimelineEvent = _styled("div", {
  target: "ecl7qxd0"
})("width:5px;height:65px;background-color:", COLORS.PRIMARY, ";transform:rotate(270deg);position:relative;top:65%;border-radius:5px;", function (_ref) {
  var variant = _ref.variant;

  switch (variant) {
    case 'past':
      return "\n          top: 30%;\n          opacity: 0.1;\n        ";

    default:
      return "";
  }
});
TimelineEvent.propTypes = {
  variant: PropTypes.string
};
TimelineEvent.defaultProps = {
  variant: 'past'
};