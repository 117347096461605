import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
export function TwitterIcon(props) {
  return ___EmotionJSX("svg", _extends({
    width: "125",
    height: "102",
    viewBox: "0 0 125 102",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), ___EmotionJSX("g", {
    id: "Personal-Page",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    id: "Contact-Desktop",
    transform: "translate(-843 -466)",
    fill: "#55ACEE",
    fillRule: "nonzero"
  }, ___EmotionJSX("g", {
    id: "Twitter",
    transform: "translate(843 466)"
  }, ___EmotionJSX("path", {
    d: "M125,12.0745618 C120.400801,14.1225684 115.458228,15.5067289 110.270584,16.1292567 C115.565675,12.9422445 119.631753,7.8959123 121.5466,1.88319276 C116.591682,4.83377291 111.103186,6.97681138 105.261714,8.13188526 C100.583416,3.12733034 93.9184863,0 86.5425767,0 C72.3792017,0 60.8965215,11.5291613 60.8965215,25.7495161 C60.8965215,27.7676818 61.1237607,29.7330519 61.5608652,31.6176219 C40.2469915,30.5438072 21.3504518,20.2922972 8.70185449,4.71349098 C6.4943853,8.5166038 5.2297085,12.9399491 5.2297085,17.6589491 C5.2297085,26.5924079 9.75758076,34.4740884 16.6387761,39.0916292 C12.4346172,38.9580337 8.48055889,37.7997462 5.02258677,35.8711033 C5.02075791,35.9785307 5.02075791,36.0864172 5.02075791,36.1947627 C5.02075791,48.6710294 13.8606935,59.0786309 25.5925601,61.4438692 C23.4404148,62.0324246 21.1748784,62.3469021 18.8357292,62.3469021 C17.1833278,62.3469021 15.5766487,62.1857607 14.0111196,61.885056 C17.2743151,72.1154482 26.7452174,79.5600736 37.9672812,79.7680418 C29.1904422,86.674153 18.1325213,90.7908256 6.11763413,90.7908256 C4.04778887,90.7908256 2.00629138,90.6691662 0,90.4313567 C11.3491715,97.7373365 24.8294561,102 39.3119719,102 C86.4826802,102 112.277333,62.7628387 112.277333,28.7354463 C112.277333,27.6189362 112.252643,26.5083942 112.203263,25.4042794 C117.213504,21.773785 121.561688,17.2384214 125,12.0745618",
    id: "Path"
  })))));
}