import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { css } from '@emotion/core';
var _ref = {
  name: "l3sd2o",
  styles: "@media screen and (min-width:940px){display:none;}"
};
export function HiddenUpLg(_ref2) {
  var children = _ref2.children;
  return ___EmotionJSX("div", {
    css: _ref
  }, children);
}