import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function CSS3Icon() {
  return ___EmotionJSX("svg", {
    width: "96",
    height: "135",
    viewBox: "0 0 96 135",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-268 -625)"
  }, ___EmotionJSX("g", {
    id: "CSS-Icon",
    transform: "translate(268 625)"
  }, ___EmotionJSX("g", null, ___EmotionJSX("g", {
    fillRule: "nonzero"
  }, ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "47.9412462 135 8.73658384 124.203938 0 27 96 27 87.2541531 124.188711"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "78.5719295 118.243621 86 35 47 35 47 127"
  }), ___EmotionJSX("polygon", {
    fill: "#FB3455",
    points: "20 70 21.1167566 82 49 82 49 70"
  }), ___EmotionJSX("polygon", {
    fill: "#FB3455",
    points: "18 45 19.0513226 57 47 57 47 45"
  }), ___EmotionJSX("polygon", {
    fill: "#FB3455",
    points: "47 100.823813 46.9500457 100.837455 34.2546214 97.3223612 33.4430521 88 22 88 23.5970319 106.353085 46.9475354 113 47 112.985071"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "23 0 37 0 37 6.00000001 28.8545455 6.00000001 28.8545455 12 37 12 37 18 23 18"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "41 0 55 0 55 5.14285716 46.8545453 5.14285716 46.8545453 6.17142858 55 6.17142858 55 18 41 18 41 12.6 49.1454543 12.6 49.1454543 11.5714286 41 11.5714286"
  }), ___EmotionJSX("polygon", {
    fill: "#FFF",
    points: "59 0 73 0 73 5.14285716 64.8545453 5.14285716 64.8545453 6.17142858 73 6.17142858 73 18 59 18 59 12.6 67.1454547 12.6 67.1454547 11.5714286 59 11.5714286"
  }), ___EmotionJSX("polygon", {
    fill: "#FB3455",
    points: "75.9100337 69.3068332 78 45 49 45 49 57.0092872 65.3128506 57.0092872 64.2594546 69.3068332 49 69.3068332 49 81.3158557 63.2555161 81.3158557 61.9117483 96.8908255 49 100.505962 49 113 72.7330204 106.176727 72.9071415 104.147808 75.6275645 72.53163"
  })))))));
}