import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { COLORS } from '../../@theme';
import { LoveIcon, Important, Hero } from '../../@components/@atoms';
export function DesktopHero() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Hero.DesktopSectionHighlight, null, ___EmotionJSX(Hero.DesktopIllustrationContainer, null, ___EmotionJSX(Hero.Illustration, null)), ___EmotionJSX(Hero.GreetingContainer, null, ___EmotionJSX(Hero.Greeting, {
    color: COLORS.TEXT_PRIMARY,
    align: "left",
    maxWidth: 469,
    margin: "unset"
  }, "Hello,", ___EmotionJSX("br", null), ___EmotionJSX(Hero.MyName, {
    color: COLORS.PRIMARY
  }, "I am Carlos,"), ___EmotionJSX("br", null), "Front-End developer and UI enthusiast"))), ___EmotionJSX(Hero.CoreValues, null, ___EmotionJSX(Hero.LoveValue, {
    centerContents: true,
    marginTop: 0,
    marginBottom: 4
  }, ___EmotionJSX(Hero.Subtitle, {
    marginRight: 4
  }, "I ", ___EmotionJSX(Important, null, "love"), " developing user interfaces"), ___EmotionJSX(LoveIcon, null)), ___EmotionJSX(Hero.Subtitle, null, "Making people\u2019s lives better through software is my goal")));
}