import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function LoveIcon() {
  return ___EmotionJSX("svg", {
    width: "44px",
    height: "44px",
    viewBox: "0 0 44 44",
    version: "1.1"
  }, ___EmotionJSX("g", {
    id: "Personal-Page",
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    id: "Landing-Desktop",
    transform: "translate(-763.000000, -801.000000)",
    fill: "#FB3455",
    fillRule: "nonzero"
  }, ___EmotionJSX("g", {
    id: "Second-Heading",
    transform: "translate(270.000000, 801.000000)"
  }, ___EmotionJSX("path", {
    d: "M515,0 C502.849736,0 493,9.8497355 493,22 C493,34.1502645 502.849736,44 515,44 C527.150264,44 537,34.1502645 537,22 C536.986223,9.85544677 527.144553,0.0137771582 515,0 Z M515,41 C504.50659,41 496,32.4934102 496,22 C496,11.5065898 504.50659,3 515,3 C525.49341,3 534,11.5065898 534,22 C534,32.4934102 525.49341,41 515,41 Z M518.7,15.25 C517.345194,15.4074776 516.062523,15.9448128 515,16.8 C513.935181,15.9441697 512.650088,15.4068366 511.293,15.25 C509.346633,15.214329 507.52247,16.1959581 506.48,17.84 C505.511101,19.3956406 505.423916,21.3440392 506.25,22.98 C507.037,24.54 511.965,28.69 514.05,30.41 C514.602601,30.8622418 515.397399,30.8622418 515.95,30.41 C518.034,28.7 522.965,24.54 523.75,22.97 C524.569468,21.3304614 524.474711,19.3822731 523.5,17.83 C522.469588,16.1808679 520.643924,15.1995735 518.7,15.25 Z M521.064,21.63 C520.664,22.35 517.774,24.97 515,27.3 C512.822587,25.5816404 510.791916,23.6850988 508.929,21.63 C508.574272,20.9257302 508.614497,20.0870868 509.035,19.42 C509.527835,18.6586887 510.38581,18.2139252 511.292,18.25 C512.287713,18.5575457 513.20041,19.0875872 513.961,19.8 C514.541496,20.3574843 515.458504,20.3574843 516.039,19.8 C516.797281,19.0886273 517.707137,18.5586472 518.7,18.25 C519.604102,18.1985483 520.464616,18.6429814 520.946,19.41 C521.371342,20.0780631 521.417213,20.9196656 521.067,21.63 L521.064,21.63 Z",
    id: "Like"
  })))));
}