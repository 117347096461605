import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { space, breakpoints } from '../../../@theme';
export var Subtitle = _styled("h2", {
  target: "ekkjfpi0"
})("font-weight:normal;font-size:", space(6), "px;", function (_ref) {
  var align = _ref.align;
  return align && "text-align: " + align;
}, ";", function (_ref2) {
  var marginRight = _ref2.marginRight;
  return marginRight && "margin-right: " + space(marginRight) + "px";
}, ";@media screen and (min-width:768px) and (max-width:939px){font-size:", space(8), "px;}@media screen and (min-width:940px) and (min-height:1024px){font-size:", space(8), "px;}", breakpoints.xl, "{font-size:", space(8), "px;}", breakpoints.uw, "{font-size:", space(10), "px;}", breakpoints.t1, "{font-size:", space(8), "px;}", breakpoints.t3, "{font-size:", space(10), "px;}", breakpoints.t4, "{font-size:", space(12), "px;}");
Subtitle.defaultProps = {
  align: 'center'
};
Subtitle.propTypes = {
  align: PropTypes.string
};