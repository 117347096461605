import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
export function FslLogo(props) {
  return ___EmotionJSX("svg", _extends({
    width: "324px",
    height: "62px",
    viewBox: "0 0 220 42",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, props), ___EmotionJSX("g", {
    id: "Personal-Page",
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    id: "Desktop-HD",
    transform: "translate(-116.000000, -249.000000)"
  }, ___EmotionJSX("g", {
    id: "Fsl-SVG",
    transform: "translate(116.000000, 249.000000)"
  }, ___EmotionJSX("g", {
    id: "Symbol",
    transform: "translate(0.558938, 0.019288)",
    fillRule: "nonzero"
  }, ___EmotionJSX("path", {
    d: "M13.031718,19.5789723 L8.65571003,17.0528127 L24.2344373,8.98071175 L39.3545848,17.0132852 L34.8932949,19.4577669 L24.1154765,13.8883446 L13.031718,19.5789723 Z M9.85658058,21.2091526 L9.83640735,21.21951 L10.5997167,21.6381465 L13.8883005,23.5365633 L13.9298724,23.464568 L24.1154765,29.0508555 L38.3025798,21.21951 L42.4556241,18.6607113 L47.4410623,21.3092237 L24.2344373,33.9807117 L0.441062307,21.3092237 L5.49427847,18.6909052 L9.85658058,21.2091526 Z",
    id: "Combined-Shape",
    fill: "#2F65FF"
  }), ___EmotionJSX("path", {
    d: "M24,1.42108547e-14 L48,12.9610924 L24,25.6824744 L-4.79616347e-13,12.9610924 L24,1.42108547e-14 Z M24.143933,4.50688498 L9.07040881,12.9610924 L24.143933,21.3117147 L38.8334355,12.9636127 L24.143933,4.50688498 Z",
    id: "Combined-Shape",
    fill: "#2F65FF"
  }), ___EmotionJSX("path", {
    d: "M4.98230032,27.2635442 L11.5665924,31.064116 L24.31603,37.801957 L38.4410623,30.5719832 L43.6684019,27.5532856 L47.4410623,29.5751868 L24.5047517,41.9807117 L0.441062307,29.7266159 L4.98230032,27.2635442 Z M9.10643009,25.0267033 L17.5745145,20.4337929 L20.7412116,22.21049 L12.1119645,26.7615518 L9.10643009,25.0267033 Z M39.5209091,25.3304986 L36.4416826,27.1086982 L27.2462742,22.3006573 L30.4810896,20.485742 L39.5209091,25.3304986 Z",
    id: "Combined-Shape",
    fill: "#2F65FF"
  })), ___EmotionJSX("text", {
    fill: "#000000",
    fontFamily: "NeuzeitGro",
    fontSize: "26",
    fontWeight: "normal"
  }, ___EmotionJSX("tspan", {
    x: "63.0000003",
    y: "27.9999997"
  }, "FullStack Labs"))))));
}