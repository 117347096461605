import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
export function HTML5Icon() {
  return ___EmotionJSX("svg", {
    width: "101",
    height: "139",
    viewBox: "0 0 101 139",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, ___EmotionJSX("defs", null, ___EmotionJSX("polygon", {
    id: "path-1",
    points: "101 28 91.7986403 127.888398 50.4381861 139 9.19161425 127.904047 0 28"
  }), ___EmotionJSX("polygon", {
    id: "path-2",
    points: "51 131 85.0005395 121.958087 93 36 51 36"
  }), ___EmotionJSX("polygon", {
    id: "path-3",
    points: "10 0 16.1753661 0 16.1753661 5.94403567 21.8243663 5.94403567 21.8243663 0 28 0 28 18 21.8246339 18 21.8246339 11.9725392 16.1756337 11.9725392 16.1756337 18 10.0002676 18 10.0002676 0"
  }), ___EmotionJSX("polygon", {
    id: "path-4",
    points: "35.7389831 5.9690632 30 5.9690632 30 0 48 0 48 5.9690632 42.2584748 5.9690632 42.2584748 18 35.7392656 18 35.7392656 5.9690632"
  }), ___EmotionJSX("polygon", {
    id: "path-5",
    points: "51 0 57.8111006 0 62.000849 6.32466252 66.1863514 0 73 0 73 18 66.4948793 18 66.4948793 9.07821099 62.000849 15.4782168 61.8884769 15.4782168 57.3913332 9.07821099 57.3913332 18 51 18"
  }), ___EmotionJSX("polygon", {
    id: "path-6",
    points: "77 0 83.6504132 0 83.6504132 12.0502288 93 12.0502288 93 18 77 18"
  })), ___EmotionJSX("g", {
    id: "Personal-Page",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    id: "Technologies-Desktop",
    transform: "translate(-81 -555)"
  }, ___EmotionJSX("g", {
    id: "HTML-Icon",
    transform: "translate(81 555)"
  }, ___EmotionJSX("g", {
    id: "HTML-Logo",
    fillRule: "nonzero"
  }, ___EmotionJSX("g", {
    id: "Path"
  }, ___EmotionJSX("use", {
    fill: "#E44D26",
    xlinkHref: "#path-1"
  }), ___EmotionJSX("use", {
    fill: "#FFF",
    xlinkHref: "#path-1"
  })), ___EmotionJSX("g", {
    id: "Path"
  }, ___EmotionJSX("use", {
    fill: "#F16529",
    xlinkHref: "#path-2"
  }), ___EmotionJSX("use", {
    fill: "#FFF",
    xlinkHref: "#path-2"
  })), ___EmotionJSX("polygon", {
    id: "Path",
    fill: "#FB3455",
    points: "31.9468156 59.9048369 50 59.9048369 50 48 18 48 18.3054765 51.1938656 21.4420206 84 50 84 50 72.0954255 33.1130783 72.0954255"
  }), ___EmotionJSX("polygon", {
    id: "Path",
    fill: "#FB3455",
    points: "35.9007742 91 24 91 25.6609131 110.087208 49.9454368 117 50 116.984474 50 104.336765 49.9480475 104.350954 36.7448063 100.695256"
  }), ___EmotionJSX("g", {
    id: "Path"
  }, ___EmotionJSX("use", {
    fill: "#000",
    xlinkHref: "#path-3"
  }), ___EmotionJSX("use", {
    fill: "#FFF",
    xlinkHref: "#path-3"
  })), ___EmotionJSX("g", {
    id: "Path"
  }, ___EmotionJSX("use", {
    fill: "#000",
    xlinkHref: "#path-4"
  }), ___EmotionJSX("use", {
    fill: "#FFF",
    xlinkHref: "#path-4"
  })), ___EmotionJSX("g", {
    id: "Path"
  }, ___EmotionJSX("use", {
    fill: "#000",
    xlinkHref: "#path-5"
  }), ___EmotionJSX("use", {
    fill: "#FFF",
    xlinkHref: "#path-5"
  })), ___EmotionJSX("g", {
    id: "Path"
  }, ___EmotionJSX("use", {
    fill: "#000",
    xlinkHref: "#path-6"
  }), ___EmotionJSX("use", {
    fill: "#FFF",
    xlinkHref: "#path-6"
  })), ___EmotionJSX("polygon", {
    id: "Path",
    fill: "#FB3455",
    points: "51 85.0933553 66.8924172 85.0933553 65.3943502 100.7777 51 104.418223 51 117 77.4581836 110.128811 77.6522983 108.085644 80.685096 76.2474428 81 73 51 73"
  }), ___EmotionJSX("polygon", {
    id: "Path",
    fill: "#FB3455",
    points: "51 57.9706679 51 58 81.8561982 58 82.1124708 55.3027679 82.6944983 49.2193962 83 46 51 46"
  }))))));
}