import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { space } from '../../../@theme';
export var LoveValue = _styled("div", {
  target: "er3eod70"
})(function (_ref) {
  var marginTop = _ref.marginTop;
  return marginTop && "margin-top: " + space(marginTop) + "px";
}, ";", function (_ref2) {
  var marginBottom = _ref2.marginBottom;
  return marginBottom && "margin-bottom: " + space(marginBottom) + "px";
}, ";", function (_ref3) {
  var centerContents = _ref3.centerContents;
  return centerContents && "\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  ";
}, ";");
LoveValue.defaultProps = {
  marginTop: 11,
  marginBottom: 11,
  centerContents: false
};
LoveValue.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  centerContents: PropTypes.bool
};