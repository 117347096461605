import { jsx as ___EmotionJSX } from "@emotion/core";
import React from 'react';
import { css } from '@emotion/core';
var _ref = {
  name: "bz6wa1",
  styles: "display:none;@media screen and (min-width:940px){display:block;}"
};
export function HiddenDownLg(_ref2) {
  var children = _ref2.children;
  return ___EmotionJSX("div", {
    css: _ref
  }, children);
}