import _styled from "@emotion/styled-base";
import PropTypes from 'prop-types';
import { COLORS, space } from '../../../@theme';
export var Divider = _styled("hr", {
  target: "e1vg63dv0"
})("width:5px;border-radius:5px;border:none;background-color:", COLORS.PRIMARY, ";margin-top:", space(4), "px;margin-bottom:", space(4), "px;height:75px;position:static;top:unset;transform:rotate(180deg);", function (_ref) {
  var variant = _ref.variant;

  switch (variant) {
    case 'present':
      return "\n          height: 90px;\n          position: relative;\n          top: " + space(4) + "px;\n        ";

    default:
      return '';
  }
});
Divider.propTypes = {
  variant: PropTypes.string
};
Divider.defaultProps = {
  variant: 'past'
};