import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
export function BookIcon(props) {
  return ___EmotionJSX("svg", _extends({
    width: "66",
    height: "80",
    viewBox: "0 0 66 80",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), ___EmotionJSX("g", {
    id: "Personal-Page",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    id: "About-Desktop",
    transform: "translate(-461 -608)",
    fill: "#6B34FB"
  }, ___EmotionJSX("g", {
    id: "Hobbies",
    transform: "translate(461 608)"
  }, ___EmotionJSX("path", {
    d: "M60.3166667,0 L16.7933333,0 C15.6529666,0.00839805916 14.5231791,0.217712976 13.4566667,0.618181818 L12.8333333,0 L10.3033333,2.50909091 L9.9,2.90909091 L3.41,9.34545455 C2.93353349,9.73135167 2.49744627,10.1638349 2.10833333,10.6363636 L0,12.7272727 L0.696666667,12.9272727 C0.241871436,14.0170634 0.00525184291,15.1842083 1.04212935e-13,16.3636364 L1.04212935e-13,70.9090909 C1.04212935e-13,75.9298614 4.10405646,80 9.16666667,80 L47.6666667,80 C49.2233948,79.9907672 50.7514415,79.5837334 52.1033333,78.8181818 L53.4416667,79.9272727 L60.06,72.0545455 C63.6024004,70.7335855 65.9620832,67.3894376 66,63.6363636 L66,0 L60.3166667,0 Z M61.1707317,4.83670588 L61.1707317,63.5661176 C61.1514976,64.5797363 60.6934893,65.5360232 59.9137559,66.1905882 L59.0449639,65.4748235 L57.6031387,67.2 L57.4737442,67.2 L57.4737442,6.63529412 L13.6829268,6.63529412 C14.4424944,5.4947852 15.7255266,4.80620661 17.1026403,4.8 L61.1707317,4.83670588 Z M30.5853659,12.8 L38.6341463,12.8 L38.6341463,28.8 L33.9658537,25.4486486 L30.5853659,28.2414414 L30.5853659,12.8 Z M10.044878,75.2 C8.05342006,75.2 6.43902439,73.5566217 6.43902439,71.5294118 L6.43902439,16.4705882 C6.43902439,14.4433783 8.05342006,12.8 10.044878,12.8 L26.2712195,12.8 L26.2712195,41.1185882 L34.9613268,32.9515294 L44.3004878,40.5496471 L44.3004878,12.8 L51.5121951,12.8 L51.5121951,71.5294118 C51.5121951,73.5566217 49.8977994,75.2 47.9063415,75.2 L10.044878,75.2 Z",
    id: "Shape"
  })))));
}