import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
export function NativAppsLogo(props) {
  return ___EmotionJSX("svg", _extends({
    width: "321px",
    height: "61px",
    viewBox: "0 0 321 61",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), ___EmotionJSX("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("g", {
    transform: "translate(-639.000000, -104.000000)"
  }, ___EmotionJSX("g", {
    transform: "translate(639.000000, 104.000000)"
  }, ___EmotionJSX("text", {
    fontFamily: "ArialMT, Arial",
    fontSize: "46",
    fontWeight: "normal",
    letterSpacing: "4.78999996"
  }, ___EmotionJSX("tspan", {
    x: "75",
    y: "46",
    fill: "#CB8700"
  }, "Nativ"), ___EmotionJSX("tspan", {
    x: "203.752734",
    y: "46",
    fill: "#000000"
  }, "App"), ___EmotionJSX("tspan", {
    x: "299.97039",
    y: "46",
    fill: "#000000"
  }, "s")), ___EmotionJSX("g", {
    transform: "translate(0.413058, 0.000000)"
  }, ___EmotionJSX("path", {
    d: "M9.41027235,8.77014983 L9.41027235,51.2096403 C6.46908312,48.6757374 4.26323433,45.6463009 2.79272599,42.1213308 C1.32221765,38.5963607 0.391308988,34.1066448 4.57411886e-13,28.6521831 C0.593017924,24.0675985 1.69589653,20.1515736 3.30863583,16.9041085 C4.92137512,13.6566434 6.95525396,10.9453238 9.41027235,8.77014983 Z",
    id: "Path-21",
    fill: "#D58600"
  }), ___EmotionJSX("path", {
    d: "M61.086838,9.42098516 L61.086838,51.8604756 C58.1456488,49.3265727 55.9398,46.2971363 54.4692916,42.7721662 C52.9987833,39.247196 52.0678746,34.7574802 51.6765656,29.3030185 C52.2695836,24.7184338 53.3724622,20.802409 54.9852015,17.5549438 C56.5979408,14.3074787 58.6318196,11.5961591 61.086838,9.42098516 Z",
    id: "Path-21",
    fill: "#D58600",
    transform: "translate(56.381702, 30.640730) rotate(180.000000) translate(-56.381702, -30.640730) "
  }), ___EmotionJSX("path", {
    d: "M13.8515473,9.48495143 L45.4690185,56.5959258 C40.9973583,59.3458761 36.0703919,60.6041504 30.6881192,60.3707485 C25.3058466,60.1373466 19.6936559,58.4220426 13.8515473,55.2248363 L13.8515473,9.48495143 Z",
    id: "Path-22",
    fill: "#D68700"
  }), ___EmotionJSX("path", {
    d: "M20.5556281,30.3468838 L35.7362338,53.2279521 C33.0632824,53.5859939 30.5331815,53.7650148 28.1459309,53.7650148 C25.7586804,53.7650148 23.2285795,53.5859939 20.5556281,53.2279521 L20.5556281,30.3468838 Z",
    id: "Path-23",
    fill: "#FFFFFF"
  }), ___EmotionJSX("path", {
    d: "M17.0132021,4.50220311 C20.3713513,1.42220913 24.7137818,-0.0774980413 30.0404936,0.00308159802 C35.3672054,0.0836612374 41.1306073,1.73624402 47.3306995,4.96082995 L47.3306995,49.6495022 L17.0132021,4.50220311 Z",
    id: "Path-24",
    fill: "#D28700"
  }), ___EmotionJSX("path", {
    d: "M26.1435962,7.44854888 C27.9107273,6.89445066 30.0367649,6.61740154 32.521709,6.61740154 C35.0066531,6.61740154 37.5523833,7.16100745 40.1588995,8.24821928 L40.7788131,28.7071907 L26.1435962,7.44854888 Z",
    id: "Path-25",
    fill: "#FFFFFF"
  }))))));
}